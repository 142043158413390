import React from "react"
import { graphql } from "gatsby"
import Post from "../components/template-parts/post-part"

const Scholarship = ({ location, data }) => {


return (
<Post data={data} site={data.site} location={location}/>
)}

export default Scholarship

export const query = graphql`
  query scholarship($id: String!) {
    page: wpScholarship(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              id
              resize(width: 1920, height: 1440, cropFocus: ATTENTION) {
                src
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
    awardsMenu: wpMenu(slug: { eq: "awards" }) {
      name
      menuItems {
        nodes {
          label
          url
          path
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
  }
`
