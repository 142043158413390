import React, { useState } from "react"
import Layout from "../layout"
import Seo from "../seo"
import Share from "../share"
import StaticBG from "../../assets/images/pages/parents-hero.jpg"

function Post({ data, site, location }) {

  const { title, content, featuredImage } = data.page


  const [openSection, setOpenSection] = useState(false)
  const toggleSection = () => {
    setOpenSection(!openSection)
  }

  return (
    <Layout toggleSection={toggleSection}>
    <Seo
        title={site.siteMetadata.title}
        description={site.siteMetadata.description}
      />
      <div className="static">

{!!featuredImage?.node?.localFile?.childImageSharp  ?    <section
   id="static-hero"style={{ backgroundImage: "url(" + featuredImage.node.localFile.childImageSharp.resize.src + ")" }}>&nbsp;</section> :   <section id="static-hero" style={{ backgroundImage: "url(" + StaticBG + ")" }} >&nbsp;</section>}

        <section id="static-page" className="page-pad">
          <div className="page">
            <div className="column-single">
              <h2>{title}</h2>



              <div dangerouslySetInnerHTML={{ __html: content }} />

            </div>
          </div>
        </section>
        <Share location={location} title={title} />
      </div>
    </Layout>
  )
}

export default Post
